.container_page_foods_nationalities:before {
  background: url('../../images/noisebg.png') #f3f1f1;
  content: '';
  height: 100vh;
  max-width: 100%;
  position: fixed;
  width: 100vw;
}

/* Comidas e bebidas */
.container_foods_by_nationalities {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  max-width: 100%;
  padding: 10px 20px 80px;
  position: absolute;
  top: 200px;
  width: 100vw;
}

.container_foods_by_nationalities a {
  overflow: hidden;
  width: 100%;
}

.container_foods_by_nationalities .card {
  border: solid 1px var(--dark_color);
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
  width: 100%;
}

.container_foods_by_nationalities .card_image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.selected_nationalities {

  /* background-color: aquamarine; */
  height: 40px;
  padding: 0 20px;
  position: relative;
  top: 100px;
}

.selected_nationalities label {
  background-color: white;
  border-radius: 4px;
  height: 100%;
  padding: 0 40px;
  width: 100%;

}

.selected_nationalities label select {
  height: 100%;
  width: 100%;
}

@media screen and ( min-width : 900px ) {

  .selected_nationalities {
    left: 50%;
    padding: 0;
    position: absolute;
    top: 160px;
    transform: translateX(-50%);
    width: 60vw;
  }

  .container_foods_by_nationalities {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    left: 50%;
    padding: 0 0 80px;
    position: absolute;
    transform: translateX(-50%);
    width: 60vw;
  }

}

@media screen and ( min-width : 1400px ) {

  .container_foods_by_nationalities {
    grid-template-columns: repeat(4, 1fr);
  }

}
