.container_page_done:before {
  background: url('../../images/noisebg.png') #f3f1f1;
  content: '';
  height: 100vh;
  max-width: 100%;
  position: fixed;
  width: 100vw;
}

/* Comidas e bebidas */
.main_container_done {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 10px 20px 80px;
  position: absolute;
  top: 170px;
  width: 100vw;
}

.container_menu_done {
  align-items: center;

  /* background-color: olive; */
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: space-between;
  width: 100%;
}

.container_menu_done button {
  background-color: white;
  border-radius: 30px;
  color: var(--dark_color);
  font-size: 14px;
  font-weight: 900;
  height: 100%;
  width: 100%;
}

.container_done {

  /* background-color: aqua; */
  display: flex;
  flex-direction: column-reverse;
  gap: 90px;
  padding-top: 40px;
  width: 100%;
}

.card_container_foods, .card_container_drinks {

  /* border: solid 1px; */
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin-top: 30px;
  width: 100%;
}

.done_item {
  display: grid;
  grid-template-columns: 129px 1fr;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
}

.done_item:before {
  background-color: white;
  border-radius: 10px;
  content: '';
  height: calc(100% + 40px);
  position: absolute;
  right: 0;
  top: -20px;
  width: calc(100% - 65px);
  z-index: -1;

}

.done_item > a {
  border-radius: 10px;
  display: inline-block;
  height: 129px;
  overflow: hidden;
  position: relative;
  width: 129px;
}

.done_item > a > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.done_info {

  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  position: relative;
}

.info {
  color: var(--height_color_02);
  font-family: Volkhov , serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 20px;
}

.done_info > a {
  flex-grow: 1;
}

.done_info .info_title {

  /* background-color: tomato; */
  color: var(--dark_color);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 900;
  height: 100%;
  justify-content: flex-start;
  line-height: 18px;
  padding-bottom: 10px;
}

/* tags da receita */
.container_tag {
  align-items: center;

  /* background: olive; */
  display: flex;
  gap: 10px;
  height: 24px;
  margin-bottom: 20px;
}

.container_tag > p {
  align-items: center;
  background-color: #eae8e8;
  border-radius: 30px;
  color: var(--dark_color);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  padding: 0 10px;

}

/* data de recita finalizada */
.done_date {

  /* background: tomato; */
  bottom: 0;
  color: #5e5e5e;
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  transform: translate(-70px, 40px);
}

.done_date:before {
  content: 'Done in ';
  font-size: 12px;
}

.social_info_done {
  bottom: 0;
  display: flex;
  gap: 20px;
  position: absolute;
  right: 0;
  transform: translate(-20px, 45px);
}


.social_info_done .button_share {
  background: url('../../images/compartilhar.png') var(--height_color_03);
  background-position: 45% center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  text-indent: -1000%;

  /* transform: translateY(-25px); */
  width: 50px;
}

/* menu social (curtir ou compartilhar) */
.alert_link_copied_done {
  align-items: center;
  background-color: var(--dark_color);
  border-radius: 5px;
  color: var(--height_color_03);
  display: flex;
  font-size: 14px;
  font-weight: 900;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(26px, -70px);
  width: 100px;
}

.alert_link_copied_done:after {
  background-color: var(--dark_color);
  border-radius: 2px;
  bottom: -8px;
  content: '';
  height: 20px;
  position: absolute;
  transform: rotate(45deg);
  width: 20px;
  z-index: -2;
}


@media screen and ( min-width : 600px ) {

  .container_menu_done {
    width: 50vw;
  }

  .container_done {
    display: grid;
    gap: 90px;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 40px;
    width: 50vw;
  }
}
