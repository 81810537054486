/* stylelint-disable selector-no-qualifying-type */
.card {

  /* background-color: rgb(105, 235, 12); */
  border: solid 1px var(--dark_color);
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.card:after {
  background-color: var(--dark_color);
  bottom: -0;
  content: '';
  height: 5px;
  left: 10px;
  position: absolute;
  transition: width ease-in-out 0.5s;
  width: 40px;
}

.card:hover:after {
  width: 60px;
}

/* talvez no futuro colocar a imagem da comida mais alta 200px */
img[ class ^= 'card_image' ] {
  object-fit: cover;
  width: 100%;
}

.card_image_drink {
  height: 150px;
}

.card_image_food {
  height: 200px;
}


.card_title {
  color: var(--dark_color);
  font-family: Volkhov , serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 19px;
  padding: 20px 10px;
  position: relative;
  text-decoration: none;
}
