.nav_conteiner {

  --height_nav : 70px;
  background-color: var(--dark_color);
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 50px;
  height: var(--height_nav);
  max-width: 100%;
  padding: 10px 20px;
  position: relative;
  width: 100vw;
}

.home_link {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  overflow: hidden;
}

.header_title_app {
  color: white;
  font-family: Abril Fatface , cursive;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
}

.header_title_app strong {
  color: var(--height_color_03);
  font-weight: 400;
}

.nav_title {
  align-items: flex-end;
  display: flex;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  left: 50%;
  letter-spacing: 0.09em;
  line-height: 26px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: calc(var(--height_nav) + 35px);
  transform: translateX(-50%);
}

.profile_button {
  background: url('../../images/avatar-user-svgrepo-com.svg') no-repeat center;
  background-size: 60%;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.magnifying_button {
  background: url('../../images/searchIcon.svg') no-repeat center white;
  background-size: 54%;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  height: 50px;
  padding-top: 20px;
  width: 50px;
}

.magnifying_button:hover {
  background: url('../../images/searchIcon.svg') no-repeat center var(--height_color_03);
  background-size: 54%;
}
