.container_page_foods:before, .container_page_drinks:before {
  background: url('../../images/noisebg.png') #f3f1f1;
  content: '';
  height: 100vh;
  max-width: 100%;
  position: fixed;
  width: 100vw;
}

/* Comidas e bebidas */
.main_container_foods, .main_container_drinks {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  max-width: 100%;
  padding: 10px 20px 80px;
  position: absolute;
  top: 170px;
  width: 100vw;
}

.card_container_foods {

  /* esse aqui tem responsividade no desktop */
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin-top: 30px;
  width: 100%;
}

.card_container_drinks {

  /* esse aqui tem responsividade no desktop */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 150px 150px;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

/* Filtros */
.container_filters {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 30px 30px;
  width: 100%;
}

.container_filters button {
  background-color: white;
  border-radius: 30px;
  color: var(--dark_color);
  font-size: 14px;
  font-weight: 900;
  height: 100%;
  width: 100%;
}

.container_filters .selected {
  background-color: var(--height_color_02);
  border-radius: 30px;
  color: var(--dark_color);
  font-size: 14px;
  font-weight: 900;
  height: 100%;
  width: 100%;
}


@media screen and ( min-width : 600px ) {

  .container_filters {
    left: 50%;
    transform: translateX(-50%);
    width: 60vw;
  }

  .card_container_drinks {
    display: grid;
    grid-template-columns: repeat(3, 150px);
    justify-content: center;
    left: 50%;
    margin-top: 30px;
    transform: translateX(-50%);
    width: 60vw;
  }

  .card_container_foods {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    left: 50%;
    margin-top: 30px;
    transform: translateX(-50%);
    width: 60vw;
  }

}

@media screen and ( min-width : 700px ) {

  .card_container_foods {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and ( min-width : 840px ) {

  .card_container_foods {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media screen and ( min-width : 1400px ) {

  .card_container_drinks {
    grid-template-columns: repeat(5, 150px);
  }

  .card_container_foods {
    grid-template-columns: repeat(4, 1fr);
  }

}

