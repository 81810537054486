@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,900;1,300;1,400;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

:root {
  --height_color_01 : #F2722C;
  --height_color_02 : #F9A410;
  --height_color_03 : #FCC135;
  --dark_color : #2D1E23;
  --transition-time: all 0.5s ease-in-out;
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-style: normal;
  margin:0; 
  padding:0;
  vertical-align:baseline;
  position: relative
}

.doneImg {
  width: 100px;
}
/* verificar o que essa classe faz */

body {
  font-family: 'Lato', sans-serif;
}


/* font-family: 'Lato', sans-serif;
font-family: 'Volkhov', serif; */
