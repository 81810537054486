input, label, select, button, textarea {
  background: none;
  border: 0;
  display: inline-block;

  /* Browsers have different default form fonts */
  font-size: 13px;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}
