.conteiner_footer {
  align-items: center;
  background-color: var(--height_color_02);
  border-radius: 20px 20px 0 0;
  bottom: 0;
  display: flex;
  height: 60px;
  justify-content: space-around;
  margin: 0 auto;
  position: fixed;
  width: 100%;
}

.conteiner_footer > a {
  align-items: center;

  /* background-color: coral; */
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.drinks_bottom, .explore_bottom, .food_bottom {
  cursor: pointer;
}
